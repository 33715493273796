import React, { useState, Suspense } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import "../styles/members.scss";
import { useScript } from "../utils/useScript";
import { password } from "./password.json";

const Downloads = React.lazy(() => import("../components/Downloads"));

export const MemberPageTemplate = ({ page, files }) => {
  const [loaded, error] = useScript("/bcrypt.min.js");
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const bcrypt = loaded ? window.dcodeIO.bcrypt : undefined;

  if (typeof window !== 'undefined' && !passwordCorrect && localStorage.getItem("password") && !bcrypt)
    return null;

  if (
    typeof window !== 'undefined' &&
    !passwordCorrect &&
    bcrypt &&
    localStorage.getItem("password") &&
    bcrypt.compareSync(localStorage.getItem("password"), password)
  ) {
    setPasswordCorrect(true);
  }

  return (
    <article className="members">
      <div className="members-container  container">
        <div className="titleWrapper">
          <h1>{page.frontmatter.title}</h1>
        </div>

        {!passwordCorrect && (
          <PasswordForm
            setPasswordCorrect={setPasswordCorrect}
            bcrypt={bcrypt}
          />
        )}
        {passwordCorrect && (
          <Suspense fallback="loading">
            <Downloads files={files} />
          </Suspense>
        )}
      </div>
    </article>
  );
};

const MemberPage = ({ data }) => {
  const { markdownRemark: page, files, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle }
    }
  } = page;

  const flattenedFiles = files.edges.map(edge => edge.node);

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <MemberPageTemplate
        page={{ ...page, bodyIsMarkdown: false }}
        files={flattenedFiles}
      />
    </Layout>
  );
};

MemberPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default MemberPage;

function PasswordForm({ setPasswordCorrect, bcrypt }) {
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);

  return (
    <form
      className="passwordForm"
      onSubmit={e => {
        e.preventDefault();
        if (bcrypt.compareSync(e.target.password.value, password)) {
          setPasswordCorrect(true);
          localStorage.setItem("password", e.target.password.value);
        } else {
          setPasswordIncorrect(true);
        }
      }}
    >
      <label>
        Passwort:{" "}
        <input
          type="password"
          name="password"
          className={passwordIncorrect ? " incorrect" : ""}
          onChange={() => passwordIncorrect && setPasswordIncorrect(false)}
        />
      </label>
      <input
        type="submit"
        className="submitPasswordButton"
        value="Fortfahren"
      />
    </form>
  );
}

export const trainingPageQuery = graphql`
  query DownloadsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seo {
          browserTitle
          title
          description
        }
      }
    }
    files: allFile(
      filter: {
        extension: { nin: ["jpg", "png", "svg"] }
        absolutePath: { glob: "**/media/*" }
      }
    ) {
      edges {
        node {
          relativePath
          name
        }
      }
    }
    ...LayoutFragment
  }
`;
